<template>
  <div :class="isSP ? 'painting--sp' : 'painting--pc'">
    <!-- トップビジュアル -->
    <sp-visual v-if="isSP" class="painting__visual--sp" :img="require('@/assets/img/visual/painting_sp.jpeg')" title="板金・塗装" />
    <pc-visual v-else class="painting__visual--pc" :img="require('@/assets/img/visual/painting_pc.jpeg')" title="板金・塗装" />
    <!-- タイトル -->
    <h1 v-if="isSP" class="painting__title--sp">板金・塗装</h1>
    <h1 v-else class="painting__title--pc">板金・塗装</h1>
    <!-- 詳細情報 -->
    <sp-description v-if="isSP" class="painting__description--sp" :main="description.main" :sub="description.sub" />
    <pc-description v-else class="painting__description--pc" :main="description.main" :sub="description.sub" />
    <!-- 画像集 -->
    <v-img v-if="isSP" class="painting__images--sp" :src="require('@/assets/img/painting/image.jpeg')" :aspect-ratio="301/539"  cover />
    <v-img v-else class="painting__images--pc" :src="require('@/assets/img/painting/image.jpeg')" :aspect-ratio="301/539"  cover />
    <!-- スライドショー -->
    <v-img v-if="isSP" class="painting__slideshow--sp" :src="require('@/assets/img/painting/slideshow_pc.jpeg')" :aspect-ratio="375/166"  cover />
    <v-img v-else class="painting__slideshow--pc" :src="require('@/assets/img/painting/slideshow_pc.jpeg')" :aspect-ratio="1280/559"  cover />
    <!-- 問い合わせ -->
    <sp-contact v-if="isSP" :isRecruit="false" />
    <pc-contact v-else :isRecruit="false" />
  </div>
</template>

<script>
import SpVisual from '@/components/common/visual/sp'
import PcVisual from '@/components/common/visual/pc'
import SpDescription from '@/components/common/description/sp'
import PcDescription from '@/components/common/description/pc'
import SpContact from '@/components/common/contact/sp'
import PcContact from '@/components/common/contact/pc'

export default {
  name: 'painting',
  components: { SpVisual, PcVisual, SpDescription, PcDescription, SpContact, PcContact },
  mounted () {
  },
  computed: {
    /**
     * @return {Boolean} SPデバイスかどうか
     */
    isSP () {
      return /Android|webOS|iPhone|iPad|iPod|BlackBerry|BB|PlayBook|IEMobile|Windows Phone|Kindle|Silk|Opera Mini/i.test(
        navigator.userAgent
      )
    },
    /**
     * @return {Object} 説明文
     */
    description () {
      return {
        main: '弊社の大型塗装ブースで事故修理の塗装から大型トラックの全塗装まですべて対応します。',
        sub: ''
      }
    }
  },
  methods: {
  }
}
</script>

<style lang="scss" scoped>
@import '@/assets/sass/color.scss';
@import '@/assets/sass/size.scss';

.painting {
  width: 100%;
  &--sp {
    @extend .painting;
    margin-top: $sp_header_height;
  }
  &--pc {
    @extend .painting;
    margin-top: $pc_header_height;
  }
  &__title {
    font-weight: 500;
    text-align: center;
    &--sp {
      @extend .painting__title;
      margin-top: 70px;
      font-size: 1.5rem;
    }
    &--pc {
      @extend .painting__title;
      margin-top: 171px;
      font-size: 3.5rem;
    }
  }
  &__description {
    &--sp {
      @extend .painting__description;
      margin-top: 55px;
    }
    &--pc {
      @extend .painting__description;
      margin-top: 100px;
    }
  }
  &__images {
    &--sp {
      @extend .painting__images;
      display: block;
      width: 301px;
      max-width: 80vw;
      height: 539px;
      max-height: 143.255vw;
      margin: 60px auto 0 auto;
    }
    &--pc {
      @extend .painting__images;
      display: block;
      width: 593px;
      max-width: 60vw;
      height: 1057px;
      max-height: 106.9477vw;
      margin: 79px auto 0 auto;
    }
  }
  &__slideshow {
    &--sp {
      @extend .painting__slideshow;
      margin-top: 60px;
    }
    &--pc {
      @extend .painting__slideshow;
      width: 100vw;
      height: 22.7485vw;
      margin-top: 185px;
    }
  }
}
</style>
